@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Moderat";
    src: url("../public/assets/fonts/Moderat-Semibold.woff2") format("woff2"),
      /* Modern Browsers */ url("../public/assets/fonts/Moderat-Semibold.woff")
        format("woff"); /* Older Browsers */
    font-weight: normal;
    font-style: normal;
  }
}

body {
  margin: 0;
  font-family: "Moderat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
